import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['embed', 'play', 'pause', 'sound', 'mute']

  connect() {
    this.startListeners();
    this.embedTarget.volume = 0.25
    this.checkUserAgentVideoAutoplay();
  }

  play() {
    this.embedTarget.play()
    this.playTarget.classList.toggle('hidden')
    this.pauseTarget.classList.toggle('hidden')
  }

  pause() {
    this.embedTarget.pause()
    this.playTarget.classList.toggle('hidden')
    this.pauseTarget.classList.toggle('hidden')
  }

  mute() {
    this.embedTarget.muted = true
    this.soundTarget.classList.toggle('hidden')
    this.muteTarget.classList.toggle('hidden')
  }

  sound() {
    this.embedTarget.muted = false
    this.embedTarget.volume = 0.25
    this.soundTarget.classList.toggle('hidden')
    this.muteTarget.classList.toggle('hidden')
  }

  checkUserAgentVideoAutoplay = async () => {
    const video = this.embedTarget

    try {
      await video.play();
      video.setAttribute('autoplay', true);
      this.playTarget.classList.toggle('hidden')
      this.pauseTarget.classList.toggle('hidden')

    } catch (err) {
      // console.log(err, 'video autoplay error');
    }
  }

  startListeners() {
    document.addEventListener('scroll', () => {
      let videoHeight = this.embedTarget.scrollHeight

      if (window.pageYOffset > videoHeight && !(this.embedTarget.paused)) {
        this.pause();
      }
    })


  }
}
