import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['menu', 'hideButton', 'showButton']

  show() {
    this.setShown(this.menuTarget)
    this.setShown(this.hideButtonTarget)
    this.setHidden(this.showButtonTarget)
  }

  hide() {
    this.setHidden(this.menuTarget)
    this.setHidden(this.hideButtonTarget)
    this.setShown(this.showButtonTarget)
  }

  setShown(el) {
    el.removeAttribute('hidden')
  }

  setHidden(el) {
    el.setAttribute('hidden', true)
  }
}
