// Make sure that this is paired with the video player modal component on the same page

import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {}

  openVideo() {
    var popupContainer = document.getElementById('popup-container')
    var videoPlayer = document.getElementById('video-player')
    var videoLink = this.element.getAttribute('data-src-value')

    if (videoLink) {
      videoPlayer.src = videoLink
      setTimeout(() => {
        popupContainer.classList.toggle('modal-active')
      }, 100)
    }
  }
}
