import { Controller } from 'stimulus'

export default class extends Controller {
  filter() {
    const curr_url = window.location;
    const params = `?c=${this.element.getAttribute('f')}`;
    const new_url = curr_url.protocol + '//' + curr_url.host + curr_url.pathname + '' + params;
    location.href = new_url;
  }

  all() {
    const new_url = location.protocol + '//' + location.host + location.pathname;
    location.href = new_url;
  }
}