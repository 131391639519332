import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  static targets = ['nextButton', 'prevButton', 'container']
  connect() {
    let swiper = new Swiper(this.containerTarget, {
      loop: true,
      autoplay: {
        delay: 3000,
      },
      navigation: {
        prevEl: this.prevButtonTarget,
        nextEl: this.nextButtonTarget,
      },
    })
  }
}
