import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  loadMore() {
    this.element.attr('p')
  }

  index() {
    this.request()
      .then((r) => {
        console.log(r);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  request() {
    return new Promise((resolve, reject) => {
      $.get($(this).attr('href'))
      .done(response => {
        if (!response.isEmptyObject()) {
          this.load(response);
        } else {
          reject('No more entries found.')
        }
      })
      .fail(e => {
        reject(e);
      });
    });
  }
}