import { Controller } from 'stimulus'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default class extends Controller {
  connect() {
    new Swiper(this.element, {
      loop: true,
      speed: 650,
      autoplay: {
        delay: 3000,
      },
      slidesPerView: 1,
      spaceBetween: 15,
      breakpoints: {
        420: {
          slidesPerView: 1.4,
        },
        640: {
          slidesPerView: 2,
        },
        1280: {
          slidesPerView: 3,
        },
        1680: {
          slidesPerView: 4,
        },
      },
    })
  }
}
